<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon"></Breadcrumb>
    <el-backtop target=".el-main"></el-backtop>
    <div class="search">
      <div style="display: flex; flex-wrap: wrap">
        <el-select
          v-model="valueArea"
          filterable
          clearable
          placeholder="请选择区号(可搜索关键字)"
          style="width: 220px; margin-right: 10px"
          v-if="internationType"
        >
          <el-option
            v-for="item in options"
            :key="item.name"
            :label="item.name + item.tel"
            :value="item.tel"
          >
          </el-option>
        </el-select>
        <el-input placeholder="请输入手机号" v-model="phone"></el-input>
        <el-select
          v-model="activityId"
          placeholder="请选择期号"
          style="width: 220px; margin-right: 10px"
        >
          <el-option
            v-for="item in activityOption"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <div>
          <el-button type="primary" @click="searchList">搜索</el-button>
          <el-button type="info" @click="reset">重置</el-button>
          <!-- <el-button plain @click="toggleSelection(tableData)">全 选</el-button>
          <el-button @click="handleBatchBtn()">批 量</el-button> -->
        </div>
      </div>
    </div>

    <div class="search_fifter_activity">
      <el-button
        v-for="(item, i) in activityOption.slice(0, 6)"
        :key="i"
        @click="handleActivity(item)"
      >
        {{ item }}
      </el-button>
      <p>奖励总和：{{ allRewards }}</p>
    </div>

    <div class="table_con" style="height: calc(100vh - 200px)">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        border
        @row-click="handleRowClick"
        @selection-change="handleSelectionChange"
        id="mytable"
      >
        <el-table-column type="selection" width="40" align="center">
        </el-table-column>
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="phoneArea"
          label="区号"
          width="90"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="140">
        </el-table-column>
        <el-table-column prop="userName" label="真实姓名" width="140">
        </el-table-column>
        <el-table-column prop="activityId" label="活动期号" width="90">
        </el-table-column>
        <el-table-column prop="wallet" label="钱包地址" width="260">
        </el-table-column>
        <el-table-column prop="normalRewards" label="常规奖励">
        </el-table-column>
        <el-table-column prop="extraRewards" label="额外奖励">
        </el-table-column>
        <el-table-column prop="activateNum" label="激活人数"> </el-table-column>
        <el-table-column prop="rankNo" label="活动排名"> </el-table-column>
        <el-table-column
          prop="rewardStatus"
          label="操作"
          fixed="right"
          align="center"
          width="100"
        >
          <template slot-scope="scope">
            <div class="operation">
              <el-button
                @click="handleClick(scope.row, 1)"
                type="text"
                size="small"
                >审核通过</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="handleClick(scope.row, 2)"
                >审核拒绝</el-button
              >
              <el-button
                @click="handleDetail(scope.row)"
                style="
                  background: #fff;
                  border: 1px #409eff solid;
                  color: #409eff;
                "
                size="small"
                >查看详情</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";

import * as xlsx from "xlsx";
import { hideLoading, showLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  inject: ["reload"],
  data() {
    return {
      multipleSelection: [],
      tableData: [],

      valueArea: "",
      options: [],
      phone: "",
      activityId: "",
      activityOption: [],
      allRewards: "",

      BreadcrumbCon: [
        {
          name: "资产审核",
        },
        {
          name: "火财盒",
        },
        {
          name: "待审核列表",
        },
      ], // 面包屑数据

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 200, //要传过去的数据 每页多少条数据
      pageSizes: [200, 500, 1000, 5000, 10000, 20000, 50000, 100000],

      internationType: false,
      role: "",
      phoneAreaROLE: "",
    };
  },

  mounted() {
    this.options = areaCodeData;

    this.role = localStorage.getItem("ROLE");
    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    showLoading();
    const opt = {
      url: reqUrl.huoCaiHeGetMatchBoxList,
      method: "POST",
      params: JSON.stringify({
        pageNum: 0,
        pageSize: 200,
        phone: "",
        phoneArea: this.phoneAreaROLE,
        examineStatus: 1,
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.data.matchBoxList.list) {
          this.tableData = res.data.data.matchBoxList.list;
          this.pageLength = res.data.data.matchBoxList.total;
          this.pageSize = res.data.data.matchBoxList.pageSize;

          this.allRewards = res.data.data.allRewards;
        }

        hideLoading();

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);

    const optActivity = {
      url: reqUrl.huoCaiHeGetActivityNOList,
      method: "POST",
      resFunc: (res) => {
        this.activityOption = res.data.data;
        hideLoading();

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(optActivity);
  },

  methods: {
    searchList() {
      if (this.phone || this.valueArea || this.activityId) {
        showLoading();
        const opt = {
          url: reqUrl.huoCaiHeGetMatchBoxList,
          method: "POST",
          params: JSON.stringify({
            pageNum: 0,
            pageSize: 200,
            phone: this.phone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
            examineStatus: 1,
            activityId: this.activityId,
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.data.matchBoxList.list) {
              this.tableData = res.data.data.matchBoxList.list;
              this.pageLength = res.data.data.matchBoxList.total;
              this.pageSize = res.data.data.matchBoxList.pageSize;

              this.allRewards = res.data.data.allRewards;
            }

            hideLoading();

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可搜索项",
        });
      }
    },

    handleActivity(activityId) {
      showLoading();
      const opt = {
        url: reqUrl.huoCaiHeGetMatchBoxList,
        method: "POST",
        params: JSON.stringify({
          pageNum: 0,
          pageSize: 200,
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          examineStatus: 1,
          activityId: activityId,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.data.matchBoxList.list) {
            this.tableData = res.data.data.matchBoxList.list;
            this.pageLength = res.data.data.matchBoxList.total;
            this.pageSize = res.data.data.matchBoxList.pageSize;

            this.allRewards = res.data.data.allRewards;
          }

          hideLoading();

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    handleRowClick(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },

    // 全选
    toggleSelection(rows) {
      if (rows.length > 0) {
        let rowIdsSelected = [];
        let rowNum = [];
        rows.map((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
          rowIdsSelected.push(row.id);
          rowNum.push(row.normalRewards);
        });
        var i,
          sum = 0;
        for (i = 0; i < rowNum.length; i++) {
          sum += rowNum[i];
        }
        console.log(sum);

        this.$confirm("您确定要执行此操作吗?", "提示", {
          distinguishCancelAndClose: true,
          confirmButtonText: "审核通过",
          cancelButtonText: "审核拒绝",
          type: "warning",
        })
          .then(() => {
            this.$confirm("您确定要执行此操作吗?", "提示", {
              distinguishCancelAndClose: true,
              confirmButtonText: "确认",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                const opt = {
                  url: reqUrl.huoCaiHeAuditMatchBox,
                  method: "POST",
                  params: JSON.stringify({
                    ids: rowIdsSelected,
                    examineStatus: 2,
                    allRewards: sum,
                  }),
                  resFunc: (res) => {
                    console.log(res.data);
                    if (res.data.code == 0) {
                      this.$message({
                        type: "success",
                        message: "操作成功!",
                      });
                      this.refresh();
                    } else if (res.data.code != 0) {
                      this.$notify.error({
                        title: "错误",
                        message: res.data.message,
                      });
                      hideLoading();
                    }
                  },
                  errFunc: (err) => {
                    this.$notify.error({
                      title: "错误",
                      message: err,
                    });
                    console.log(err, "err1");
                  },
                };
                request(opt);
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消操作",
                });

                this.$refs.multipleTable.clearSelection();
              });
          })
          .catch((action) => {
            if (action == "cancel") {
              this.$prompt("请输入拒绝原因", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputPattern: /^.+$/,
                inputErrorMessage: "请输入拒绝原因",
              })
                .then(({ value }) => {
                  const opt = {
                    url: reqUrl.huoCaiHeAuditMatchBox,
                    method: "POST",
                    params: JSON.stringify({
                      ids: rowIdsSelected,
                      examineStatus: 3,
                      examineReason: value,
                      allRewards: sum,
                    }),
                    resFunc: (res) => {
                      console.log(res.data);
                      if (res.data.code == 0) {
                        this.$message({
                          type: "success",
                          message: "操作成功!",
                        });
                        this.refresh();
                      } else if (res.data.code != 0) {
                        this.$notify.error({
                          title: "错误",
                          message: res.data.message,
                        });
                        hideLoading();
                      }
                    },
                    errFunc: (err) => {
                      this.$notify.error({
                        title: "错误",
                        message: err,
                      });
                      console.log(err, "err1");
                    },
                  };
                  request(opt);
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消操作",
                  });
                  this.$refs.multipleTable.clearSelection();
                });
            } else if (action == "close") {
              this.$refs.multipleTable.clearSelection();
            }
          });
      } else {
        this.$notify.error({
          title: "错误",
          message: "暂无可选项",
        });
      }
    },

    // 批量
    handleBatchBtn() {
      if (this.multipleSelection.length > 0) {
        let batchIds = [];
        let batchNum = [];
        this.multipleSelection.map((item) => {
          batchIds.push(item.id);
          batchNum.push(item.normalRewards);
        });
        var i,
          sum = 0;
        for (i = 0; i < batchNum.length; i++) {
          sum += batchNum[i];
        }

        this.$confirm("请选择您要执行的操作?", "提示", {
          distinguishCancelAndClose: true,
          confirmButtonText: "审核通过",
          cancelButtonText: "审核拒绝",
          type: "warning",
        })
          .then(() => {
            this.$confirm("您确定要执行此操作吗?", "提示", {
              distinguishCancelAndClose: true,
              confirmButtonText: "确认",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                const opt = {
                  url: reqUrl.huoCaiHeAuditMatchBox,
                  method: "POST",
                  params: JSON.stringify({
                    ids: batchIds,
                    examineStatus: 2,
                    allRewards: sum,
                  }),
                  resFunc: (res) => {
                    console.log(res.data);
                    if (res.data.code == 0) {
                      this.$message({
                        type: "success",
                        message: "操作成功!",
                      });
                      this.refresh();
                    } else if (res.data.code != 0) {
                      this.$notify.error({
                        title: "错误",
                        message: res.data.message,
                      });
                      hideLoading();
                    }
                  },
                  errFunc: (err) => {
                    this.$notify.error({
                      title: "错误",
                      message: err,
                    });
                    console.log(err, "err1");
                  },
                };
                request(opt);
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消操作",
                });

                this.$refs.multipleTable.clearSelection();
              });
          })
          .catch((action) => {
            if (action == "cancel") {
              this.$prompt("请输入拒绝原因", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputPattern: /^.+$/,
                inputErrorMessage: "请输入拒绝原因",
              })
                .then(({ value }) => {
                  const opt = {
                    url: reqUrl.huoCaiHeAuditMatchBox,
                    method: "POST",
                    params: JSON.stringify({
                      ids: batchIds,
                      examineStatus: 3,
                      examineReason: value,
                      allRewards: sum,
                    }),
                    resFunc: (res) => {
                      console.log(res.data);
                      if (res.data.code == 0) {
                        this.$message({
                          type: "success",
                          message: "操作成功!",
                        });
                        this.refresh();
                      } else if (res.data.code != 0) {
                        this.$notify.error({
                          title: "错误",
                          message: res.data.message,
                        });
                        hideLoading();
                      }
                    },
                    errFunc: (err) => {
                      this.$notify.error({
                        title: "错误",
                        message: err,
                      });
                      console.log(err, "err1");
                    },
                  };
                  request(opt);
                })
                .catch(() => {
                  console.log(456);
                  this.$message({
                    type: "info",
                    message: "已取消操作",
                  });
                  this.$refs.multipleTable.clearSelection();
                });
            } else if (action == "close") {
              this.$refs.multipleTable.clearSelection();
            }
          });
      } else {
        this.$notify.error({
          title: "错误",
          message: "暂无可选项",
        });
      }
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.huoCaiHeGetMatchBoxList,
        method: "POST",
        params: JSON.stringify({
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          examineStatus: 1,
          activityId: this.activityId,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.data.matchBoxList.list) {
            this.tableData = res.data.data.matchBoxList.list;
            this.pageLength = res.data.data.matchBoxList.total;
            this.pageSize = res.data.data.matchBoxList.pageSize;
            this.currentPage = data.pageIndex;

            this.allRewards = res.data.data.allRewards;
          }

          hideLoading();

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    handleClick(row, index) {
      let idsValue = [];
      idsValue.push(row.id);

      if (index == 1) {
        this.$confirm("您确定要执行此操作吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const opt = {
              url: reqUrl.huoCaiHeAuditMatchBox,
              method: "POST",
              params: JSON.stringify({
                ids: idsValue,
                examineStatus: 2,
                allRewards: row.normalRewards,
              }),
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                  });
                  this.refresh();
                } else if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                }
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
              },
            };
            request(opt);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });

            this.$refs.multipleTable.clearSelection();
          });
      } else if (index == 2) {
        this.$prompt("请输入拒绝原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: /^.+$/,
          inputErrorMessage: "请输入拒绝原因",
        })
          .then(({ value }) => {
            const opt = {
              url: reqUrl.huoCaiHeAuditMatchBox,
              method: "POST",
              params: JSON.stringify({
                ids: idsValue,
                examineStatus: 3,
                examineReason: value,
                allRewards: row.normalRewards,
              }),
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                  });
                  this.refresh();
                } else if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                }
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
              },
            };
            request(opt);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });

            this.$refs.multipleTable.clearSelection();
          });
      }
    },

    handleDetail(row) {
      this.$router.push({
        path: "/FireWealthBox/Detail",
        query: {
          userId: row.userId,
          activityId: row.activityId,
          page: "vait",
        },
      });
    },

    reset() {
      if (this.phone || this.valueArea || this.activityId) {
        this.phone = "";
        this.valueArea = "";
        this.activityId = "";

        // 重置后重新执行
        showLoading();
        const opt = {
          url: reqUrl.huoCaiHeGetMatchBoxList,
          method: "POST",
          params: JSON.stringify({
            pageNum: 0,
            pageSize: 200,
            phone: "",
            phoneArea: this.phoneAreaROLE,
            examineStatus: 1,
          }),
          resFunc: (res) => {
            console.log(res.data);
            this.tableData = res.data.data.matchBoxList.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;

            hideLoading();

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可重置项",
        });
      }
    },

    // 刷新
    refresh() {
      this.reload();
    },

    exportToExcel() {
      if (this.multipleSelection.length <= 0) {
        this.$message({
          message: "请先选择你要导出的数据哦",
          type: "warning",
        });
        return;
      }

      const arr = this.multipleSelection.map((item, index) => {
        return {
          序号: index + 1,
          手机号: item.phone,
          行程ID: item.travelId,
          发车时间: item.startTime,
          行程起点: item.startPlace,
          行程终点: item.endPlace,
          行程耗时: item.consumeTime,
          行程状态: item.travelStatus,
          行驶距离: item.distance,
          待奖励CPLE: item.cple,
          质押量: item.pledge,
          钱包地址: item.cpleaddress,
        };
      });
      const sheet = xlsx.utils.json_to_sheet(arr);
      const book = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(book, sheet, "sheet1");
      xlsx.writeFile(book, "行程奖励（历史）.xlsx");
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .el-input {
    width: 200px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .el-select {
    margin-right: 10px;
  }
}

.search_fifter_activity {
  display: flex;

  .el-button {
    padding: 5px 15px;
    background: #fdfcfc;
    border-radius: 10px;
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
  }

  p {
    margin-left: 20px;
    color: red;
  }
}

.pagination {
  margin-bottom: 10px;
}

.operation {
  display: flex;
  flex-direction: column;
  color: #b4b4b4;
  font-weight: bold;

  .el-button + .el-button {
    margin-left: 0;
    margin-top: 2px;
  }

  .el-button {
    border: 1px #ccc solid;
    color: #666;
    background: #d9d9d9;
  }
}
</style>
